<template lang="pug">
b-container
	b-row.justify-content-md-center.mt-3
		b-col(cols="12")
			b-card(no-body).mb-5.overflow-hidden
				b-row(no-gutters)
					b-col(cols="12" lg="6")
						b-card-img(:src="this.product.images[0].name")
						table.table.table-sm.my-2.text-center
							tr
								th(width="100px") Marca
								td(v-text="product.vehicles_products.brand.name")
								th Modelo
								td(v-text="product.vehicles_products.modelo.name")
							tr
								th Año
								td(v-text="product.vehicles_products.year")
								th Valor
								td ${{ product.price | formatNumber }}
					b-col(cols="12" lg="6")
						b-card-body
							b-card-text
								h5.text-secondary Formulario de financiamiento
								hr
								ValidationObserver(v-slot="{ invalid }")
									form( @submit.prevent="onSubmit" )
										b-row
											b-col(cols="12" md="6")
												validation-provider(v-slot="validationContext", name="nombre", rules="required|alpha_spaces|min:4")
													b-form-group
														label Nombre: (obligatorio)
														b-form-input(
															v-model="form.name",
															type="text",
															placeholder="Nombre",
															:state="getValidationState(validationContext)",
															required,
															aria-describedby="input-name-feedback"
														)
														b-form-invalid-feedback#input-name-feedback(v-text="validationContext.errors[0]")
											b-col(cols="12" md="6")
												validation-provider(v-slot="validationContext", name="apellidos", rules="required|alpha_spaces|min:4")
													b-form-group
														label Apellidos: (obligatorio)
														b-form-input(
															v-model="form.last_name",
															type="text",
															placeholder="Apellidos",
															:state="getValidationState(validationContext)",
															required,
															aria-describedby="input-last_name-feedback"
														)
														b-form-invalid-feedback#input-name-feedback(v-text="validationContext.errors[0]")
											b-col(cols="12" md="6")
												validation-provider(v-slot="validationContext", name="Rut", rules="required|rut")
													b-form-group
														label Rut: (obligatorio)
														b-form-input(
															v-model.lazy="form.rut",
															type="text"
															placeholder="11.111.111-1",
															:state="getValidationState(validationContext)"
															required
															v-rut:live
															aria-describedby="input-rut-feedback"
														)
														b-form-invalid-feedback#input-rut-feedback(v-text="validationContext.errors[0]")
											b-col(cols="12" md="6")
												validation-provider(v-slot="validationContext", name="fecha nacimiento", rules="required")
													b-form-group
														label Fecha de Nacimiento: (obligatorio)
														b-form-input(
															v-model.lazy="form.birth_date"
															placeholder="Seleccionar fecha"
															:state="getValidationState(validationContext)"
															required
															aria-describedby="input-fecha-nacimiento-feedback"
															type="date"
														)
														b-form-invalid-feedback#input-fecha-nacimiento-feedback(v-text="validationContext.errors[0]")
											b-col(cols="12" md="6")
												validation-provider(v-slot="validationContext", name="correo electrónico", rules="required|email")
													b-form-group
														label Correo electrónico: (obligatorio)
														b-form-input(
															v-model="form.email",
															type="email",
															placeholder="usuario@ejemplo.cl",
															:state="getValidationState(validationContext)",
															aria-describedby="input-email-feedback"
															required
														)
														b-form-invalid-feedback#input-email-feedback(v-text="validationContext.errors[0]")
											b-col(cols="12" md="6")
												validation-provider(v-slot="validationContext", name="teléfono", rules="required|numeric")
													b-form-group
														label Teléfono: (obligatorio)
														b-form-input(
															v-model="form.phone"
															type="tel"
															placeholder="9********"
															:state="getValidationState(validationContext)"
															aria-describedby="input-phone-feedback"
															required
														)
														b-form-invalid-feedback#input-phone-feedback(v-text="validationContext.errors[0]")
											b-col(cols="12" md="6")
												validation-provider(v-slot="validationContext" name="Pie" :rules="{ required: true, min_value: foot }")
													b-form-group
														label Pie:
															span.text-secondary.pl-1 (obligatorio)
														b-form-input(
															id="input-foot",
															v-model.lazy="form.foot",
															v-money="money"
															type="text",
															required,
															:state="getValidationState(validationContext)",
															aria-describedby="input-foot-feedback"
														)
														b-form-invalid-feedback#input-foot-feedback(v-text="validationContext.errors[0]")
											b-col(cols="12" md="6")
												validation-provider(v-slot="validationContext", name="renta", rules="required")
													b-form-group
														label Renta: (obligatoria)
														b-form-input(
															v-model="form.income",
															v-money="money"
															type="text",
															:state="getValidationState(validationContext)",,
															required,
															aria-describedby="input-income-feedback",
														)
														b-form-invalid-feedback#input-income-feedback(v-text="validationContext.errors[0]")
										b-button(type="submit" block variant="outline-okcarpremium" :disabled="invalid").font-weight-bold
											font-awesome-icon(icon="wallet")
											span.ml-1 Simular financiamiento
	b-overlay#loading(:show="success", opacity="0.7", no-wrap="")
		template(v-slot:overlay="")
			.text-center
				b-spinner(variant="okcarpremium")
				span.d-block.text-okcarpremium Espere un momento...
</template>
<script>
import { mask } from "vue-the-mask";
import { VMoney } from "v-money";

export default {
	name: "MainCredit",
	components: {},
	directives: {
		mask,
		money: VMoney,
	},
	data() {
		return {
			show: true,
			success: false,
			porcentFoot: 25,
			urlImg: this.$axios.defaults.baseURL,
			product: {
				images: [
					{ name: `${this.$axios.defaults.baseURL}/images/no_image.jpg`}
				],
				vehicles_products: {
					brand: {
						name:''
					},
					modelo: {
						name:''
					},
					year:0
				},
			},
			thumbnail: "",
			foot: "",
			form: {
				name: "",
				last_name: "",
				rut: "",
				phone: "",
				email: "",
				birth_date: "",
				foot: 0,
				income:0,
				car: {},
			},
			validStepSubmit: false,
			money: {
				decimal: ",",
				thousands: ".",
				prefix: "$",
				suffix: "",
				precision: 0,
				masked: false /* doesn't work with directive */,
			},
		};
	},
	computed: {
	},
	filters: {
		formatNumber: function (value) {
			if (!value) return "";
			value = value.toString();
			value = value.replace(/\./g, "");
			let val = (value / 1).toFixed(0).replace(".", ",");
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
		},
	},
	mounted() {
		this.getProduct();
	},
	validations: {},
	methods: {
		setVehicle: function () {
			this.form.car = {
				thumbnail: this.product.images[0].name,
				brand: this.product.vehicles_products.brand.name,
				model: this.product.vehicles_products.modelo.name,
				year: this.product.vehicles_products.year,
				price: this.cleanInteger(this.product.price),
			};
		},
		getValidationState({ dirty, validated, valid = null }) {
			return dirty || validated ? valid : null;
		},
		cleanInteger: function (value) {
			if (!value) return "";
			value = value.toString();
			value = value.replace("$", "");
			value = value.replace(/\./g, "");
			return parseInt(value, 10);
		},
		async getProduct() {
			try {
				const { data: { data } } = await this.$axios.get(`/api/v1/product/${this.$route.params.slug}/${this.$route.params.hashid}`);
				this.product = data;
				this.setVehicle();
				this.setValueFoot();
			} catch (error) {
				console.log(error);
			}
		},
		setValueFoot() {
			this.foot = Math.round((this.form.car.price / 100) * this.porcentFoot);
			this.form.foot = this.foot;
		},
		onSubmit: function () {
			this.success = true;
			this.$axios
				.post(`/api/v1/credits`, this.form)
				.then((resp) => {
					const data = resp.data;
					this.$swal({
						title: "Solicitud de financiamiento!",
						text: data.message,
						icon: "success",
						confirmButtonClass: "btn btn-outline-primary",
						confirmButtonText: "Ok",
						buttonsStyling: false,
					}).then(function () {
						const baseURL =
						process.env.NODE_ENV === "development"
							? process.env.VUE_APP_API_DEVELOMENT
							: process.env.VUE_APP_API_PRODUCTION;
						window.location.replace(`https://${document.domain}/filters?bodywork=all`);
					});
					this.success = false;
				})
				.catch((error) => {
					if (error.hasOwnProperty("response")) {
						const result = error.response;
						this.$swal({
							title: "Solicitud de financiamiento!",
							text: result.data.message,
							icon: "error",
							confirmButtonClass: "btn btn-outline-danger",
							confirmButtonText: "Ok",
							buttonsStyling: false,
						});
					}
					this.success = false;
				});
		},
	},
	watch: {
			"form.foot"(val) {
			this.form.foot = this.cleanInteger(val);
		}
	},
};
</script>
<style lang="scss">

.custom-select {
	border-radius: 20px;
}
.card {
	border-radius: 20px !important;
	overflow: hidden;
}
.vue-form-wizard {
	button.wizard-btn {
		border-radius: 20px !important;
	}
}
.min-vh-100-main {
	min-height: calc(100vh - 274px);
}
</style>
